/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';

import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import DeveloperBar from '../components/DeveloperBar/DeveloperBar';

export const pageQuery = graphql`
  query PageByIdQuery(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      databaseId
      content
      title
      pageFields {
        subtitle
        schemaJson
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
  }
`;

const InternalPage = ({ data: { page } }) => {
  const { subtitle, schemaJson } = page.pageFields || {};

  return (
    <>
      {!!page.seo && (
        <Seo
          title={page.seo.title}
          description={page.seo.metaDesc}
          canonical={page.seo.canonical}
          schema={schemaJson}
        />
      )}

      <DeveloperBar postId={page.databaseId} />

      <BrandGrid />

      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg={8}>
            <header>
              <h1 className="no-misc text-muted mb-1 fw-light page-header mb-1 text-center">
                {parse(page.title)}
              </h1>
              {subtitle && (
                <h2 className="h1 h1--center text-center">{subtitle}</h2>
              )}
            </header>
          </Col>
        </Row>
      </Container>

      {!!page.content && (
        <section className="ui-wordpress-content">
          {parse(page.content)}
        </section>
      )}
    </>
  );
};

export default InternalPage;
