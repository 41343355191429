import React, { useState } from 'react';
import { number } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'react-bootstrap/Button';

import { FaWordpress } from '@react-icons/all-files/fa/FaWordpress';
import { IoMdCloseCircleOutline } from '@react-icons/all-files/io/IoMdCloseCircleOutline';

const propTypes = {
  postId: number.isRequired,
};

const DeveloperBar = ({ postId }) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            isProduction
            cmsUrl
          }
        }
      }
    `,
  );

  const handleClose = () => setIsOpen(false);
  const adminUrl = `${siteMetadata.cmsUrl}/wp-admin/index.php`;
  const editUrl = `${siteMetadata.cmsUrl}/wp-admin/post.php?post=${postId}&action=edit`;

  return siteMetadata.isProduction || !isOpen ? null : (
    <div className="developer-bar">
      <div>
        <Button
          as="a"
          variant="link"
          href={adminUrl}
          target="_blank"
          rel="noreferrer"
          size="sm"
          className="d-flex align-items-center text-white fw-light text-capitalize"
        >
          <FaWordpress size={22} className="text-muted me-2" />
          Admin Panel
        </Button>
      </div>
      <div>
        <Button
          as="a"
          variant="link"
          href={editUrl}
          target="_blank"
          rel="noreferrer"
          className="me-4 bg-white py-2 px-3 lh-1 text-capitalize"
          size="sm"
        >
          Edit content
        </Button>
        <Button
          variant="link"
          size="sm"
          onClick={handleClose}
          className="text-muted"
        >
          <IoMdCloseCircleOutline size={26} />
        </Button>
      </div>
    </div>
  );
};

DeveloperBar.propTypes = propTypes;

export default DeveloperBar;
